import React, { PropsWithChildren } from "react";
import clsx from "clsx";
import ListEntry, {
  isLink,
  isRenderFunction,
  ListEntryValue,
} from "../../Models/CommonProps/ListEntry";
import { LinkText } from "../Common/hyperlinks";
import { H3Heading } from "../Common/Heading";
import styles from "./EntryPair.module.scss";

interface EntryPairProps extends ListEntry {
  readonly wrapper?: (
    children: PropsWithChildren<{ readonly className?: string }>
  ) => JSX.Element;
  readonly className?: string;
}

const renderValue = (value: ListEntryValue | undefined) => {
  if (isRenderFunction(value)) {
    return value();
  }
  if (Array.isArray(value)) {
    return (
      <ul>
        {value.map((val) => (
          <li key={val}>{val}</li>
        ))}
      </ul>
    );
  }
  if (isLink(value)) {
    if (value.external)
      return (
        <a target="_blank" rel="noopener noreferrer" href={value.link}>
          {value.label}
        </a>
      );
    return <LinkText link={value.link} text={value.label} />;
  }
  if (typeof value === "string") return value.trim().length > 0 ? value : "--";

  return value;
};

const EntryPair = ({
  label,
  value,
  wrapper: Wrapper,
  className,
}: EntryPairProps) => {
  if (!value) return null;
  const content = (
    <>
      <H3Heading text={label} />
      {renderValue(value)}
    </>
  );
  if (Wrapper) {
    return (
      <Wrapper className={clsx(styles.entryPair, className)}>{content}</Wrapper>
    );
  }
  return <li className={clsx(styles.entryPair, className)}>{content}</li>;
};

export default EntryPair;
