import React, { useState } from "react";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./PrivateMenu.module.scss";
import { getUsername, signOut, isCBUser, IsCommercialHorizon } from "../../Authentication/Auth";
import { AuditUrls } from "../../views/AuditManagement";
import { AuditorUrls } from "../../views/AuditorManagement";
import { CompanyUrls } from "../../views/CompanyManagement";
import { CBUrls } from "../../views/CBManagement";
import { UserUrls } from "../../views/UserManagement";
import { getUserPrimaryOrg} from "../../Authentication/Auth";
import { isCompanyUser} from "../../Authentication/Auth";
import {
  PATH_ADMINISTRATION,
  PATH_DASHBOARDS,
  PATH_NOTIFICATION_MANAGEMENT,
  PATH_REPORT_MANAGEMENT,
  PATH_IFS_REPORT_MANAGEMENT,
  raiseQuery,
} from "../Paths/paths";
import TextButton from "../TextButton";
import { useUserPermission } from "../../routing/contexts/PermissionContext";
import PermissionArea from "../../Authentication/model/PermissionArea";
import { Permission } from "../../Authentication/model/Permission";
import { RecallUrls } from "../../views/RecallManagement";

interface NavigationLink {
  readonly link: string;
  readonly text: string;
  readonly permissionTargets: PermissionArea[];
  readonly separated?: boolean;
  readonly route?: string; // add route property as optional
}

const menuItems: NavigationLink[] = [
  {
    link: AuditUrls.searchTable,
    text: "Audits",
    permissionTargets: [PermissionArea.AUDIT],
  },
  {
    link: AuditorUrls.searchTable,
    text: "Auditors",
    permissionTargets: [PermissionArea.AUDITOR],
  },
  {
    link: CompanyUrls.searchTable,
    text: "Companies",
    permissionTargets: [PermissionArea.COMPANY],
  },
  {
    link: RecallUrls.searchTable,
    text: "Recalls",
    permissionTargets: [PermissionArea.RECALL],
  },
  {
    link: CBUrls.searchTable,
    text: "Certification Bodies",
    permissionTargets: [PermissionArea.CB, PermissionArea.SUB_OFFICE],
  },
  {
    link: UserUrls.searchTable,
    text: "Users",
    permissionTargets: [PermissionArea.USER],
  },
  {
    link: PATH_REPORT_MANAGEMENT,
    text: "Reports",
    permissionTargets: [PermissionArea.REPORT],
  },
  {
    link: PATH_NOTIFICATION_MANAGEMENT,
    text: "Notifications",
    permissionTargets: [],
  },
  {
    link: "",
    route: raiseQuery,
    text: "Raise a Query",
    permissionTargets: [PermissionArea.CB],
  },
  {
    link: PATH_DASHBOARDS,
    text: "Analytics",
    permissionTargets: [PermissionArea.ANALYTIC],
  },
  {
    link: PATH_IFS_REPORT_MANAGEMENT,
    text: "IFS Audit Report",
    permissionTargets: [PermissionArea.IFS],
  },
  {
    link: PATH_ADMINISTRATION,
    text: "Administration",
    permissionTargets: [PermissionArea.ADMINISTRATION],
    separated: true,
  },
];

const PrivateMenu = () => {
  const [isOpen, setOpen] = useState(true);
  const userPermission = useUserPermission();

  const hasPermission = (permissionTargets: PermissionArea[]) =>
    permissionTargets.length === 0 ||
    permissionTargets.some((permissionTarget) =>
      userPermission[permissionTarget]?.some(
        (permission) => permission === Permission.VIEW
      )
    );

  const handleRaiseQueryClick = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();
    window.open(raiseQuery, "_blank");
  };
  const companyUser=isCompanyUser();
  let propertyValue="";
  if(companyUser==true)
  {
  const primaryOrg=getUserPrimaryOrg();
  const jsonData=JSON.parse(primaryOrg);
  
  let propertyNameCo = "co"
  let propertyNameCb="cb"
  let propertyNamea = "a"
  let propertyNameo="o";
  let propertyNames="s";
  
  if( jsonData[propertyNameCo].length==1)
  {
    propertyValue= jsonData[propertyNameCo];
  }
  else if(jsonData[propertyNameCb].length==1)
  {
    propertyValue= jsonData[propertyNameCb];
  }
  else if(jsonData[propertyNamea].length==1)
  {
    propertyValue= jsonData[propertyNamea];
  }
  else if(jsonData[propertyNameo].length==1)
  {
    propertyValue= jsonData[propertyNameo];
  }
  else if(jsonData[propertyNames].length==1)
  {
    propertyValue= jsonData[propertyNames];
  }
  }
  // logs a JavaScript object, not a string

  
  return (
    <>
      <div className={clsx(styles.menuSpacing, { [styles.open]: isOpen })} />
      <nav
        className={clsx(styles.menu, styles.menuSpacing, {
          [styles.open]: isOpen,
        })}
      >
        <IconButton
          onClick={() => setOpen((prev) => !prev)}
          className={styles.toggleButton}
        >
          {isOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>

        <div className={styles.content}>
          <h1>BRCGS</h1>
          <h2>Directory</h2>
          <div className={styles.account}>
            <AccountCircleIcon />
            <b>{getUsername()}</b>
            {companyUser==true && <b>Company ID: {propertyValue}
       </b>}
            <TextButton onClick={signOut}>Sign out</TextButton>
          </div>
          {menuItems
            .filter((item) => hasPermission(item.permissionTargets))
            .map((item) => {
              if(item.text==="Notifications" && IsCommercialHorizon())
                {
                  return null;
                }
              if (item.route) {
                if (item.text === "Raise a Query" && !isCBUser()) {
                  return null;
                }
                // check if the item has a route property
                // render the "Raise a Query" link as an anchor tag with an onClick event handler
                const target =
                  item.text === "Raise a Query" ? "_blank" : undefined;
                return (
                  <a
                    href={item.route}
                    key={item.text}
                    className={clsx(styles.link, {
                      [styles.separated]: item.separated,
                    })}
                    onClick={
                      item.text === "Raise a Query"
                        ? handleRaiseQueryClick
                        : undefined
                    }
                    target={target}
                    rel={
                      target === "_blank" ? "noopener noreferrer" : undefined
                    }
                  >
                    {item.text}
                  </a>
                );
              }
              // render other links as NavLink components
              return (
                <NavLink
                  to={item.link}
                  key={item.text}
                  className={clsx(styles.link, {
                    [styles.separated]: item.separated,
                  })}
                  activeClassName={styles.activeLink}
                >
                  {item.text}
                </NavLink>
              );
            })}
        </div>
      </nav>
    </>
  );
};

export default PrivateMenu;
