import React, { PropsWithChildren, useState } from "react";
import clsx from "clsx";
import LoadingOverlay from "../../views/PublicDirectory/components/LoadingOverlay";
import ContentPage, { ContentPageProps } from "../ContentPage";
import useMenuItems, {
  ArchiveMenuItemConfig,
  DetailsPageMenuItem,
  MenuItemConfig,
} from "./menu-items/useMenuItems";
import TabsBar from "../Common/Tab";
import "./details-page.scss";

interface DetailsPageProps extends ContentPageProps {
  readonly id?: number | string;
  readonly detailsPageMenuItems?: DetailsPageMenuItem[];
  readonly detailsPageMenuConfig?: MenuItemConfig;
  readonly archiveConfig?: ArchiveMenuItemConfig;
  readonly contentTabs?: {
    readonly name?: string;
    readonly content: React.ReactNode;
  }[];
  readonly isLoading: boolean;
}

const DetailsPage = ({
  id,
  title,
  children,
  className,
  detailsPageMenuItems = [],
  detailsPageMenuConfig = {},
  archiveConfig,
  isLoading,
  menuItems: additionalMenuItems = [],
  contentTabs = [],
}: PropsWithChildren<DetailsPageProps>) => {
  const menuItems = useMenuItems(
    id,
    detailsPageMenuItems,
    detailsPageMenuConfig,
    archiveConfig
  );

  const [selectedTab, setSelectedTab] = useState(0);

  const singleTab = contentTabs?.length === 1;
  if (isLoading) {
    return <LoadingOverlay isLoading />;
  }
  return (
    <ContentPage
      title={title}
      menuItems={[...menuItems, ...additionalMenuItems]}
      className={clsx("details-page", className)}
    >
      {children}
      {contentTabs?.length > 0 && (
        <div
          className={clsx("details-page__tab-content", {
            "details-page__tab-content--single": singleTab,
          })}
        >
          <TabsBar
            labels={contentTabs?.map((tab) => tab.name ?? "")}
            value={selectedTab}
            onChange={(e) => setSelectedTab(e)}
            centered={false}
            className="details-page__tab-content__tabs"
          />

          <div className="details-page__tab-content__main">
            {contentTabs[selectedTab]?.content}
          </div>
        </div>
      )}
    </ContentPage>
  );
};

export default DetailsPage;
