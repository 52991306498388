import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import ContentPage from "../../components/ContentPage";
import TabsBar from "../../components/Common/Tab";
import Share from "./components/Share";
import Unshare from "./components/Unshare";
import getShareEntityDetails, {
  SharableDetails,
} from "./http/getShareEntityDetails";
import SharingType from "./models/SharingType";
import { EntityTypeContext } from "../../routing/GenericRoutes";
import Snackbar from "../../components/Snackbar";
import { usePermissionContext } from "../../routing/contexts/PermissionContext";

/*
Results are not paginated as of today for the unshared table, but the table is.
This causes the checked state to "reset" on page change.
Storing changed values as a workaround until server returns paginated results
to avoid rewriting the table just for this case.
 */
export const updatedCells: {
  [key: number]: boolean;
} = {};

const Sharing = ({
  match: {
    params: { id },
  },
}: RouteComponentProps<{ readonly id: string }>) => {
  const type = useContext(EntityTypeContext)?.type as SharingType;
  const [itemData, setData] = useState<SharableDetails | null>(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState<{
    readonly shared: boolean;
    readonly name: string;
  } | null>(null);

  const permissions = usePermissionContext();
  useEffect(() => {
    if (id && type) {
      getShareEntityDetails(id, type, permissions)
        .then((data) => setData(data))
        .catch(() => {
          setData(null);
        });
    }
  }, [id, type, permissions]);

  useEffect(() => {
    return () => {
      Object.keys(updatedCells).forEach((key) => delete updatedCells[+key]);
    };
  }, []);

  if (!id) {
    return <p>Missing ID</p>;
  }
  if (!Object.values(SharingType).some((val) => type === val)) {
    return <p>Missing type</p>;
  }

  if (itemData && !itemData.isAbleToRemoveShare && !itemData.isAbleToShare) {
    return (
      <ContentPage title={`Share ${itemData?.name ?? ""}`}>
        <p>You are not allowed to share / unshare this resource</p>
      </ContentPage>
    );
  }

  const tabs: { title: string; component: JSX.Element }[] = [
    ...(itemData?.isAbleToShare
      ? [
          {
            title: "Share",
            component: (
              <Share
                id={id}
                type={type}
                setShowConfirmation={setShowConfirmation}
              />
            ),
          },
        ]
      : []),
    ...(itemData?.isAbleToRemoveShare
      ? [
          {
            title: "Shared with",
            component: (
              <Unshare
                id={id}
                type={type}
                setShowConfirmation={setShowConfirmation}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <ContentPage title={`Share ${itemData?.name ?? ""}`}>
      <TabsBar
        value={selectedTabIndex}
        onChange={setSelectedTabIndex}
        labels={tabs.map((tab) => tab.title)}
        centered={false}
      />
      {tabs[selectedTabIndex]?.component}
      <Snackbar
        message={`${
          showConfirmation?.shared ? "Shared" : "No longer sharing"
        } with ${showConfirmation?.name}`}
        close={() => setShowConfirmation(null)}
        open={!!showConfirmation}
      />
    </ContentPage>
  );
};

export default Sharing;
