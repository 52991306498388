// eslint-disable-next-line max-classes-per-file
class BackendService {
  protected readonly root: string;

  constructor(root: string) {
    this.root = root;
  }

  toString = () => this.root;
}

class PublicService extends BackendService {
  readonly PUBLIC_DIRECTORY: string = `${this.root}/PublicDirectory`;

  constructor() {
    super("/PublicDirectory/api");
  }
}

class AuditService extends BackendService {
  readonly AUDIT: string = `${this.root}/Audit`;

  constructor() {
    super("/audit/api");
  }
}

class AuditorService extends BackendService {
  readonly AUDITOR: string = `${this.root}/Auditor`;

  constructor() {
    super("/auditor/api");
  }
}

class CompanyService extends BackendService {
  readonly SITES: string = `${this.root}/Sites`;

  readonly COMPANY: string = `${this.root}/Companies`;

  readonly COUNTRIES: string = `${this.root}/Countries`;

  constructor() {
    super("/companydirectory/api");
  }
}

class CertificationService extends BackendService {
  readonly CERTIFICATION_BODY: string = `${this.root}/CertificationBody`;

  constructor() {
    super("/CertificationBody/api");
  }
}

class UserService extends BackendService {
  readonly USER: string = `${this.root}/User`;

  constructor() {
    super("/user/api");
  }
}

class ReportService extends BackendService {
  readonly REPORT: string = `${this.root}/Report`;

  readonly DASHBOARD: string = `${this.root}/Dashboard`;

  constructor() {
    super("/report/api");
  }
}

class NotificationService extends BackendService {
  readonly NOTIFICATION: string = `${this.root}/Notification`;

  constructor() {
    super("/notification/api");
  }
}

class DashboardService extends BackendService {
  readonly DASHBOARD: string = `${this.root}/dashboard`;

  constructor() {
    super("/dashboard/api");
  }
}

class RecallService extends BackendService {
  readonly RECALL: string = `${this.root}/Recall`;

  constructor() {
    super("/recall/api");
  }
}

const BackendServices = {
  PUBLIC_SERVICE: new PublicService(),
  AUDIT_SERVICE: new AuditService(),
  AUDITOR_SERVICE: new AuditorService(),
  COMPANY_SERVICE: new CompanyService(),
  CERTIFICATION_SERVICE: new CertificationService(),
  USER_SERVICE: new UserService(),
  REPORT_SERVICE: new ReportService(),
  NOTIFICATION_SERVICE: new NotificationService(),
  DASHBOARD_SERVICE: new DashboardService(),
  RECALL_SERVICE: new RecallService(),
};

export default BackendServices;
