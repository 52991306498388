/* eslint-disable react/destructuring-assignment */

import Toolbar from "@material-ui/core/Toolbar";
import * as React from "react";
import DateRangePicker, { DateRangeValue } from "../../../DateRangePicker";
import SearchBox from "../../../SearchBox";
import styles from "./DataTableToolbar.module.scss";
import LinkProps from "../../../../Models/CommonProps/LinkModel";
import { IconType } from "../../../Icon";
import DataTableAddButton from "./DataTableAddButton";

export interface DataTableToolbarOptions {
  readonly searchField?: {
    readonly enable?: boolean;
    readonly placeholder?: string;
  };
  readonly enableDateRange?: boolean;
  readonly newEntityLink?: LinkProps;
}

interface DataTableToolbarProps {
  readonly options?: DataTableToolbarOptions;
  readonly setDateRange: (val: DateRangeValue) => void;
  readonly dateRange: DateRangeValue;
  readonly dataManager: { readonly changeSearchText: (val: string) => void };
  readonly onSearchChanged: (val: string) => void;
}

class DataTableToolbar extends React.Component<
  DataTableToolbarProps,
  { searchText: string }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      searchText: props.searchText,
    };
  }

  onSearchChange = (searchText: string) => {
    this.props.dataManager.changeSearchText(searchText);
    this.setState({ searchText });
  };

  render() {
    const { searchText } = this.state;
    const { options, dateRange, setDateRange } = this.props;
    const { enableDateRange, searchField, newEntityLink } = options ?? {};
    const { enable = true, placeholder = "Search name" } = searchField ?? {};
    return (
      <Toolbar className={styles.tableToolbar}>
        {enable && (
          <SearchBox
            searchString={searchText}
            onUpdateSearch={(val) => this.onSearchChange(val)}
            handleClick={() => this.props.onSearchChanged(searchText)}
            placeHolderString={placeholder}
          />
        )}
        {enableDateRange && (
          <DateRangePicker
            fromValue={dateRange[0]}
            toValue={dateRange[1]}
            onChange={setDateRange}
          />
        )}
        {newEntityLink && (
          <div className={styles.actions}>
            <DataTableAddButton
              label={newEntityLink.label}
              link={newEntityLink.link}
              type={IconType.ADD}
              permissionTargets={newEntityLink.permissionAreas}
              state={newEntityLink.stateInfo}
            />
          </div>
        )}
      </Toolbar>
    );
  }
}

export default DataTableToolbar;
