import React from "react";
import { Route, Switch } from "react-router-dom";
import Container from "@material-ui/core/Container";
import {
  PATH_ADMINISTRATION,
  PATH_DASHBOARDS,
  PATH_NOTIFICATION_MANAGEMENT,
  PATH_REPORT_MANAGEMENT,
  PATH_IFS_REPORT_MANAGEMENT,
} from "../components/Paths/paths";
import AuditManagement, { AuditUrls } from "../views/AuditManagement";
import UserManagement, { UserUrls } from "../views/UserManagement";
import NotificationManagement from "../views/NotificationManagement";
import ReportManagement from "../views/ReportManagement";
import RecallRoutes, { RecallUrls } from "../views/RecallManagement";
import CompanyManagement, { CompanyUrls } from "../views/CompanyManagement";
import AuditorRoutes, { AuditorUrls } from "../views/AuditorManagement";
import CBRoutes, { CBUrls } from "../views/CBManagement";
import SiteRoutes, { SiteUrls } from "../views/SiteManagement";
import PrivateMenu from "../components/PrivateMenu";
import GlobalError from "./GlobalError";
import DashboardsPOC from "../views/Dashboards/DashboardPOC";
import Dashboards from "../views/Dashboards";
import Administration from "../views/Administration";
import IFSReportManagement from "../views/IFSReportManagement";

export default function PrivateDirectoryPages() {
  return (
    <div className="private">
      <PrivateMenu />
      <div className="page-container">
        <Container maxWidth="xl">
          <Switch>
            <Route path={AuditUrls.searchTable} component={AuditManagement} />
            <Route path={AuditorUrls.searchTable} component={AuditorRoutes} />
            <Route path={CBUrls.searchTable} component={CBRoutes} />
            <Route path={PATH_REPORT_MANAGEMENT} component={ReportManagement} />
            <Route
              path={CompanyUrls.searchTable}
              component={CompanyManagement}
            />
            <Route path={SiteUrls.searchTable} component={SiteRoutes} />
            <Route path={RecallUrls.searchTable} component={RecallRoutes} />
            <Route path={UserUrls.searchTable} component={UserManagement} />
            <Route
              path={PATH_NOTIFICATION_MANAGEMENT}
              component={NotificationManagement}
            />
            <Route path={`${PATH_DASHBOARDS}/poc`} component={DashboardsPOC} />
            <Route path={PATH_DASHBOARDS} component={Dashboards} />
            <Route path={PATH_ADMINISTRATION} component={Administration} />
            <Route
              path={PATH_IFS_REPORT_MANAGEMENT}
              component={IFSReportManagement}
            />
          </Switch>
        </Container>
        <GlobalError />
      </div>
    </div>
  );
}
