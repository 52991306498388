const publicArea = "";
export const privateArea = "/private";

/// //////// Public pages ///////////
export const trainingCourses = `https://www.brcgs.com/directory/brcgs-training-courses/`;
export const about = `https://www.brcgs.com/directory/about-the-directory/`;
export const accessLogos = `https://www.brcgs.com/directory/access-and-logos/`;
export const contact = `https://www.brcgs.com/directory/contact-information/`;
export const auditSiteSharing = `https://www.brcgs.com/directory/audit-and-site-sharing/`;
export const raiseQuery = `https://eu.jotform.com/BRCGS_forms/directoryqueryform`;
export const publicPage = `${publicArea}/`;
export const eula = `${publicArea}/eula`;
export const termsConditions = `${publicArea}/terms-conditions`;
export const privacyPolicy = `${publicArea}/privacy-policy`;

/// //////// Private pages ///////////
export const PATH_REPORT_MANAGEMENT = `${privateArea}/report`;
export const PATH_NOTIFICATION_MANAGEMENT = `${privateArea}/notification`;
export const PATH_DASHBOARDS = `${privateArea}/dashboard`;
export const PATH_ADMINISTRATION = `${privateArea}/administration`;
export const PATH_IFS_REPORT_MANAGEMENT = `${privateArea}/IFSAudit`;

/// //////// API children ///////////
export const AccreditationBodies = "/AccreditationBodies";
export const Sites = "/Sites";
export const Modules = "/Modules";

export const all = "/all";

/// //////// Extensions ///////////
export const details = "/details";
export const edit = "/edit";
export const create = "/create";
export const office = "/office";
export const site = "/site";
export const idParam = "/:id";
export const archive = "/Archive";
export const unarchive = "/Unarchive";
export const certificationStatus = "/CertificationStatus";
export const schedules = "/schedules";
export const expiryReasons = "/ExpiryReasons";
export const scheduleTypes = "/ScheduleTypes";
export const API_REPORT = "/Report";
export const uploadDocument = "/UploadDocument";
export const getTrainingTypes = "/getTrainingTypes";
export const deleteAuditorCategory = "/deleteAuditorCategory";
export const suspendAuditorCategory = "/suspendAuditorCategory";
export const unsuspendAuditorCategory = "/unsuspendAuditorCategory";
export const synchroniseDetailsLocation = "/synchroniseDetailsLocation";
export const synchroniseDetailsSiteName = "/SynchroniseDetailsSiteName";
export const search = "/search";
export const getNotSharingReasons = "/getNotSharingReasons";
export const getCBoptions = "/getCertificationBodyOptions";
export const postToggleNotification = "/ToggleNotification";
export const changeLog = "/changeLog";

/// ////////////////
