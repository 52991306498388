export enum Permission {
  VIEW = "View",
  GENERATE = "Generate",
  REVISE = "Revise",
  CHANGES = "Changes",
  PERSIST = "Persist",
  VERIFY = "Verify",
  REMOVE = "Remove",
  SHARE = "Share",
  UNSHARE = "Unshare",
  SCHEDULE = "Schedule",
  SUSPEND = "Suspend",
  INITIALIZE = "Initialize",
}

export default Permission;
